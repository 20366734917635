import { useContext, useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { AuthContext } from "../context/AuthContext";
import axios from "axios";
import {
  API_URL,
  TRAILERS,
  TRUCKS,
  formatName,
  isExpiredJWT,
  revertName,
  rutaFilter,
} from "../utils";
import { Button, MultiSelect, NumberInput, Select } from "@mantine/core";
import { useNavigate } from "react-router-dom";
import useFeedback from "../hooks/useFeedback";
import CustomError from "./CustomError";
import { TimeInput } from "@mantine/dates";
import useRute from "../hooks/useRute";

let cfgUsers = [];

function CreateTransport() {
  const { auth, refresh } = useContext(AuthContext);
  const isAdmin = auth.user.permissions.includes("Admin");
  const [users, setUsers] = useState(null);
  const [err, setErr] = useState(null);
  const [price, setPrice] = useState(null);
  const rute = useRute();
  const ruteArr = isAdmin ? rute.map((ruta) => ruta.number) : rute;
  const setFeedback = useFeedback();
  const navigate = useNavigate();

  const errorCfg = {
    added_by_user: {
      func: (v) => cfgUsers.find((user) => user.name === revertName(v)),
      msg: "Nume utilizator invalid",
    },
    truck: { func: (v) => TRUCKS.includes(v), msg: "Numar camion invalid" },
    trailer: {
      func: (v) => TRAILERS.includes(v),
      msg: "Numar remorca invalid",
    },
    tur: { func: (v) => v > 0, msg: "Numar tur invalid" },
    ruta: {
      func1: (arr) => arr.every((val) => ruteArr.includes(val)),
      msg1: "Valoare ruta invalida",
      func2: (arr) => arr.length > 0,
      msg2: "Campul este obligatoriu",
    },
    kms: { func: (v) => v > 0, msg: "Numar kilometri invalid" },
    time: {
      func: (v) =>
        v !== "00:00" && /^([0-1][0-9]|2[0-3]):([0-5][0-9])$/.test(v),
      msg: "Durata invalida",
    },
    price: { func: (v) => v >= 0, msg: "Pret invalid" },
  };

  const {
    control,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm({
    defaultValues: {
      date: "",
      added_by_user: "",
      truck: "",
      trailer: "",
      tur: null,
      ruta: [],
      kms: null,
      time: "00:00",
      pending: !isAdmin,
      price: null,
    },
  });

  // GET USERS
  useEffect(() => {
    async function getUsers() {
      try {
        const res = await axios.get(API_URL + "/api/user/?active=true", {
          headers: { Authorization: `Bearer ${auth.aT}` },
        });
        cfgUsers = res.data;
        setUsers(res.data);
      } catch (err) {
        console.error(err.response);
        setErr(err);
      }
    }

    if (isAdmin) {
      if (isExpiredJWT(auth.user.exp)) refresh();
      else getUsers();
    } else setUsers(auth.user);
  }, [auth, refresh, isAdmin]);

  async function onSubmit(data) {
    data.date = new Date().toISOString();
    const user = isAdmin
      ? users.find((user) => user.name === revertName(data.added_by_user))
      : auth.user;
    data.added_by_user = user._id;
    data.ruta = data.ruta.map((val) => Number(val));

    const aT = isExpiredJWT(auth.user.exp) ? await refresh() : auth.aT;
    try {
      await axios.post(API_URL + `/api/transport/create`, data, {
        headers: { Authorization: `Bearer ${aT}` },
      });
      setFeedback({
        type: "Success",
        active: true,
        msg: "Transportul a fost creat cu succes.",
      });
      navigate("/");
    } catch (err) {
      console.error(err);
      setFeedback({
        type: "Error",
        active: true,
        msg: "Transportul nu a putut fi creat.",
      });
    }
  }

  function formatNames() {
    return users.map((user) => formatName(user.name));
  }

  async function updatePriceField(e) {
    let qs = "";

    e.forEach((v, i) => {
      qs += `ruta=${v}`;
      if (i < e.length - 1) qs += "&";
    });

    if (e.length && isAdmin) {
      let accessToken = auth.aT;
      if (isExpiredJWT(auth.user.exp)) accessToken = await refresh();

      try {
        const res = await axios.get(API_URL + "/api/price/?" + qs, {
          headers: { Authorization: `Bearer ${accessToken}` },
        });
        setPrice(res.data.price);
        setValue("price", res.data.price);
      } catch (err) {
        console.error(err);
      }
    } else {
      setPrice(null);
      setValue("price", null);
    }
  }

  return err ? (
    <CustomError error={err} />
  ) : (
    <div>
      <h1 className="text-2xl font-bold mb-4">Creeaza transport</h1>
      {users && (
        <form
          onSubmit={handleSubmit(onSubmit)}
          className="max-w-[400px] flex flex-col gap-2"
        >
          {isAdmin && (
            <>
              <Controller
                name="added_by_user"
                control={control}
                rules={{
                  validate: (v) =>
                    errorCfg.added_by_user.func(v) ||
                    errorCfg.added_by_user.msg,
                }}
                render={({ field }) => (
                  <Select
                    {...field}
                    label="Sofer"
                    withAsterisk
                    placeholder="Selecteaza un sofer..."
                    nothingFoundMessage="Sofer inexistent"
                    searchable
                    data={formatNames()}
                  />
                )}
              />
            </>
          )}

          <Controller
            name="truck"
            control={control}
            rules={{
              validate: (val) => errorCfg.truck.func(val) || errorCfg.truck.msg,
            }}
            render={({ field }) => (
              <Select
                {...field}
                label="Camion"
                placeholder="Selecteaza un camion..."
                nothingFoundMessage="Camion inexistent"
                withAsterisk
                searchable
                data={TRUCKS}
              />
            )}
          />
          <Controller
            name="trailer"
            control={control}
            rules={{
              validate: (val) =>
                errorCfg.trailer.func(val) || errorCfg.trailer.msg,
            }}
            render={({ field }) => (
              <Select
                {...field}
                label="Remorca"
                placeholder="Selecteaza o remorca..."
                nothingFoundMessage="Remorca inexistenta"
                withAsterisk
                searchable
                data={TRAILERS}
              />
            )}
          />
          <Controller
            name="tur"
            control={control}
            rules={{
              validate: (val) => errorCfg.tur.func(val) || errorCfg.tur.msg,
            }}
            render={({ field }) => (
              <NumberInput
                {...field}
                label="Tur"
                error={errors.tur ? errors.tur.message : ""}
                placeholder="Numar tur..."
                min={1}
                withAsterisk
              />
            )}
          />
          <Controller
            name="ruta"
            control={control}
            rules={{
              validate: {
                validValue: (val) =>
                  errorCfg.ruta.func1(val) || errorCfg.ruta.msg1,
                notEmpty: (val) =>
                  errorCfg.ruta.func2(val) || errorCfg.ruta.msg2,
              },
            }}
            render={({ field }) => (
              <MultiSelect
                {...field}
                label="Ruta"
                error={errors.ruta ? errors.ruta.message : ""}
                placeholder="Adauga ruta..."
                data={ruteArr}
                searchable
                filter={rutaFilter}
                required
                clearable
                onChange={(e) => {
                  updatePriceField(e);
                  field.onChange(e);
                }}
                nothingFoundMessage="Ruta invalida..."
              />
            )}
          />
          <Controller
            name="kms"
            control={control}
            rules={{
              validate: (v) => errorCfg.kms.func(v) || errorCfg.kms.msg,
            }}
            render={({ field }) => (
              <NumberInput
                {...field}
                label="Kilometri"
                error={errors.kms ? errors.kms.message : ""}
                placeholder="Numar kilometri..."
                min={1}
                withAsterisk
              />
            )}
          />
          <Controller
            name="time"
            control={control}
            rules={{
              validate: (v) => errorCfg.time.func(v) || errorCfg.time.msg,
            }}
            render={({ field }) => (
              <TimeInput
                {...field}
                error={errors.time ? errors.time.message : ""}
                label="Durata"
                withAsterisk
              />
            )}
          />

          {isAdmin && (
            <>
              <Controller
                name="price"
                control={control}
                rules={{
                  validate: (val) =>
                    errorCfg.price.func(val) || errorCfg.price.msg,
                }}
                render={({ field }) => (
                  <NumberInput
                    {...field}
                    label="Pret"
                    error={errors.price ? errors.price.message : ""}
                    placeholder="Pret transport..."
                    value={price || ""}
                  />
                )}
              />
            </>
          )}

          <Button
            type="submit"
            className="!w-[100px] mt-2"
            onClick={handleSubmit(onSubmit)}
          >
            Trimite
          </Button>
        </form>
      )}
    </div>
  );
}

export default CreateTransport;
