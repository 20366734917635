import { useContext } from "react";
import { AuthContext } from "../context/AuthContext";
import { Link } from "react-router-dom";

function Dashboard() {
  const { auth } = useContext(AuthContext);
  if (!auth.aT) return;

  const isAdmin = auth.user.permissions.includes("Admin");

  return (
    <div className="flex flex-col items-center">
      <h1 className="font-bold text-2xl mb-5">Meniu</h1>
      <div className="flex flex-col gap-5">
        {isAdmin && (
          <>
            <Card color="orange" link="/list">
              Lista transporturi
            </Card>
            <Card color="green" link="/calculator">
              Calculator
            </Card>
          </>
        )}{" "}
        <Card color="blue" link="/create">
          Creeaza transport
        </Card>
        {!isAdmin && (
          <Card color="green" link="/history">
            Istoric
          </Card>
        )}
        {isAdmin && (
          <Card color="rose" link="/rute">
            Rute
          </Card>
        )}
        {isAdmin && (
          <Card color="rose" link="/users">
            Utilizatori
          </Card>
        )}
      </div>
    </div>
  );
}

export default Dashboard;

function Card({ children, link, color }) {
  return (
    // rendering invisible elements to have colors at compile
    <>
      <span
        aria-hidden="true"
        className="bg-blue-300 hover:bg-blue-500 hidden "
      ></span>
      <span className="bg-orange-300 hover:bg-orange-500 hidden "></span>
      <span className="bg-green-300 hover:bg-green-500 hidden "></span>
      <span className="bg-rose-300 hover:bg-rose-500 hidden "></span>
      <Link
        to={link}
        className={`w-[200px] h-[75px] bg-${color}-300 hover:bg-${color}-500 rounded font-bold flex items-center justify-center transition`}
      >
        {children}
      </Link>
    </>
  );
}
