import { RouterProvider, createBrowserRouter } from "react-router-dom";
import Layout from "./components/Layout";
import Dashboard from "./components/Dashboard";
import LoginPage from "./components/LoginPage";
import PersistLogin from "./components/PersistLogin";
import CreateTransport from "./components/CreateTransport";
import ListTransports from "./components/ListTransports";
import ShowTransport from "./components/ShowTransport";
import Calculator from "./components/Calculator";
import {
  Alert,
  MantineProvider,
  MultiSelect,
  Select,
  createTheme,
} from "@mantine/core";
import "./index.css";
import "@mantine/core/styles.css";
import "@mantine/dates/styles.css";
import "dayjs/locale/ro";
import RouterError from "./components/RouterError";
import { DatesProvider } from "@mantine/dates";
import History from "./components/History";
import Rute from "./components/Rute";
import Users from "./components/Users";

const router = createBrowserRouter([
  {
    Component: Layout,
    errorElement: <RouterError />,
    children: [
      {
        path: "login",
        Component: LoginPage,
        errorElement: <RouterError />,
      },

      {
        path: "/",
        Component: PersistLogin,
        errorElement: <RouterError />,
        children: [
          {
            index: true,
            Component: Dashboard,
            errorElement: <RouterError />,
          },
          {
            path: "list",
            Component: ListTransports,
            errorElement: <RouterError />,
          },
          {
            path: "list/:transportId",
            Component: ShowTransport,
            errorElement: <RouterError />,
          },
          {
            path: "create",
            Component: CreateTransport,
            errorElement: <RouterError />,
          },
          {
            path: "calculator",
            Component: Calculator,
            errorElement: <RouterError />,
          },
          {
            path: "history",
            Component: History,
            errorElement: <RouterError />,
          },
          {
            path: "rute",
            Component: Rute,
            errorElement: <RouterError />,
          },
          {
            path: "users",
            Component: Users,
            errorElement: <RouterError />,
          },
        ],
      },
    ],
  },
]);

const theme = createTheme({
  components: {
    Select: Select.extend({
      classNames: {
        dropdown: "!border-black",
      },
    }),
    MultiSelect: MultiSelect.extend({
      classNames: {
        dropdown: "!border-black",
      },
    }),
    Alert: Alert.extend({
      classNames: {
        root: "!fixed top-[30px] -translate-x-1/2 !left-1/2 z-[20000000] w-[250px]",
      },
    }),
  },
});

function App() {
  return (
    <MantineProvider theme={theme}>
      <DatesProvider settings={{ timezone: "Europe/Bucharest", locale: "ro" }}>
        <RouterProvider
          router={router}
          fallbackElement={<p>Initial Load...</p>}
        />
      </DatesProvider>
    </MantineProvider>
  );
}

export default App;
