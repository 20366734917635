import { useContext } from "react";
import { NavLink } from "react-router-dom";
import { AuthContext } from "../context/AuthContext";

function Navbar({ toggle, opened }) {
  const { auth } = useContext(AuthContext);
  const isAdmin = auth.user.permissions.includes("Admin");

  return (
    <div className="flex flex-col font-bold relative gap-3">
      <NavbarLink link="/" toggle={toggle}>
        Acasa
      </NavbarLink>
      <NavbarLink link="/create" toggle={toggle}>
        Creeaza transport
      </NavbarLink>
      {!isAdmin && (
        <NavbarLink link="/history" toggle={toggle}>
          Istoric
        </NavbarLink>
      )}
      {isAdmin && (
        <>
          <NavbarLink link="/list" toggle={toggle}>
            Lista transporturi
          </NavbarLink>
          <NavbarLink link="/calculator" toggle={toggle}>
            Calculator
          </NavbarLink>
          <NavbarLink link="/rute" toggle={toggle}>
            Rute
          </NavbarLink>
          <NavbarLink link="/users" toggle={toggle}>
            Utilizatori
          </NavbarLink>
        </>
      )}
    </div>
  );
}

export default Navbar;

function NavbarLink({ link, toggle, children }) {
  return (
    <NavLink
      to={link}
      onClick={toggle}
      className={({ isActive }) =>
        `px-1 w-[150px] hover:bg-sky-500 transition rounded-sm ${
          isActive ? "bg-sky-500" : ""
        }`
      }
    >
      {children}
    </NavLink>
  );
}
